import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import cn from "./zh_chs.json";
import tw from "./tw.json";
import vi from "./viet.json";
import { userLanguage } from "./detectLang";
import Cookies from "js-cookie";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const lngsList = ["viet", "en", "cn", "tw"];

const getDefaultLanguage = () => {
  const pathLang = window.location.pathname.split("/")[1];
  if (lngsList.includes(pathLang)) {
    return pathLang;
  }
  return "tw";
};

const defaultLang = getDefaultLanguage();

if (!lngsList.includes(localStorage.getItem("i18nextLng"))) {
  localStorage.setItem("i18nextLng", defaultLang);
}

i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: defaultLang, //當url中沒有語言代碼時，語言會被設定為viet
    // lng:
    //   Cookies.get("language") !== undefined ? Cookies.get("language") : "viet",
    // : defaultLang === "en"
    // ? "en"
    // : defaultLang === "zh_chs"
    // ? "zh_chs"
    // : defaultLang === "tw"
    // ? "tw"
    // : defaultLang === "vi"
    // ? "viet"
    // : "en",
    resources: {
      en: { translation: en },
      tw: { translation: tw },
      viet: { translation: vi },
      cn: { translation: cn },
      //tag: { translation: tag },
    },
    detection: {
      order: ["path", "localStorage", "cookie", "htmlTag", "subdomain"],
      caches: [""],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      whitelist: lngsList,
      checkWhitelist: true,
    },
    fallbackLng: "viet",
    supportedLngs: lngsList,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
