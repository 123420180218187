const PublicResultReducers = (state = {}, action) => {
  switch (action.type) {
    case "STORE_PUBLIC_RESULT":
      return action.payload;
    case "CLEAR_PUBLIC_RESULT":
      return {};

    default:
      return state;
  }
};
export default PublicResultReducers;
