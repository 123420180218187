const GlobalLoadingReducers = (state = false, action) => {
  switch (action.type) {
    case "START_LOADING":
      return (state = true);
    case "END_LOADING":
      return (state = false);

    default:
      return (state = false);
  }
};
export default GlobalLoadingReducers;
