import "./App.css";
import {
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { router } from "./utils/route";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { STORE_MEMBER_INFO } from "./redux/actions/action";
import Cookies from "js-cookie";
import { isLoggedIn } from "./utils/isLooggedIn";
import "./i18n/i18n";
import Home from "./page/Home";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // 取得當前路徑

  return (
    <Box as="main">
      <Routes>
        <Route path="*" element={<Home />} />
      </Routes>
    </Box>
  );
}

export default App;
