import React from "react";
import { Box, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import block2 from "../images/Common/block2.webp"; // 背景圖

const BlockTwo = ({ data }) => {
  if (!data || !data.data) return null;

  const { tag6_memo } = data.data;

  return (
    <>
      <Box
        position="relative"
        width="100%"
        maxWidth="600px"
        margin="auto"
        padding="0 30px"
        paddingTop="80px"
        backgroundImage={`url(${block2})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={{ base: "contain", md: "contain", lg: "100% 100%" }}
        height="400px" // 讓背景圖有足夠高度顯示
      >
        <VStack
          spacing={2}
          padding="20px"
          overflow="hidden"
          textAlign="center"
          height="100%"
        >
          <Box
            dangerouslySetInnerHTML={{ __html: tag6_memo }}
            color="white"
            wordBreak="break-word"
            overflowWrap="break-word"
            height="400px"
            fontSize="18px"
            overflow="hidden"
          />
          {/* <Text
            fontSize="16px"
            color="white"
            wordBreak="break-word"
            overflowWrap="break-word"
            height="400px"
            overflow="hidden"
          >
            Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang
            Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang
            Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang
            Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang
            Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang
          </Text> */}
        </VStack>
      </Box>
      {/* <Text fontSize="16px" color="white" textAlign="center" padding="10px 0">
        Ho tro ngan tang Ho tro ngan tang
      </Text> */}
    </>
  );
};

export default BlockTwo;
