import dayjs from "dayjs";

const NormalListReducers = (
  state = {
    normal: {
      date: dayjs().format("YYYY-MM-DD"),
    },
    normal: {
      date: dayjs().format("YYYY-MM-DD"),
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_NORMAL_LOTTERY_LIST":
      return (state = {
        ...action.payload,
      });

    case "CLEAR_NORMAL_LOTTERY_LIST":
      return (state = {});
    default:
      return state;
  }
};
export default NormalListReducers;
