import mainLogo from "../images/Logo/main-logo.webp";
import loggedInLogo from "../images/Logo/loggedInLogo.webp";
import mainLogoWhite from "../images/Logo/main-logo-white.webp";

import mainLogoDark from "../images/Logo/main-logo-dark.webp";
import mainLogoOrigin from "../images/Logo/911BOLD.webp";

export const stylesConfig = {
  mainLogo: mainLogo,
  loggedInLogo: loggedInLogo,
  mainLogoWhite: mainLogoWhite,
  mainLogoDark: mainLogoDark,
  mainLogoOrigin: mainLogoOrigin,
};
