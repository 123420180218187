import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  SimpleGrid,
  Link,
  VStack,
} from "@chakra-ui/react";
import block3 from "../images/Common/block3.webp";
import grass from "../images/Common/grass.webp";
import sampleGame from "../images/Common/sampleGame.webp";
import Title1BG from "../images/Common/title1BG.webp";
import LazyImage from "./lazyImage";

// const gameCategories = [
//   {
//     title: "SLOT",
//     games: [
//       { id: 1, image: sampleGame, link: "/game1" },
//       { id: 2, image: sampleGame, link: "/game2" },
//       { id: 3, image: sampleGame, link: "/game3" },
//     ],
//   },
//   {
//     title: "Game Bai",
//     games: [
//       { id: 6, image: sampleGame, link: "/game3" },
//       { id: 7, image: sampleGame, link: "/game4" },
//     ],
//   },
//   {
//     title: "Live Casino",
//     games: [
//       { id: 8, image: sampleGame, link: "/game5" },
//       { id: 9, image: sampleGame, link: "/game1" },
//     ],
//   },
//   {
//     title: "Lottery",
//     games: [
//       { id: 10, image: sampleGame, link: "/game5" },
//       { id: 11, image: sampleGame, link: "/game1" },
//       { id: 12, image: sampleGame, link: "/game2" },
//     ],
//   },
// ];

const HotGames = ({ data }) => {
  if (!data || !data.data) return null;

  const { hash, tag7_pic } = data.data;

  // 根據tag7_pic的項目個數決定區域高度
  const boxHeight =
    tag7_pic && tag7_pic.length <= 3
      ? { base: "400px", md: "450px", lg: "750px" }
      : { base: "650px", md: "700px", lg: "880px" };

  return (
    <Box
      backgroundImage={`url(${grass})`}
      backgroundSize="contain"
      backgroundRepeat="repeat"
      padding="20px"
      paddingTop="0"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      position="relative"
      _after={{
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "100px",
        backgroundImage:
          "linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0))",
        pointerEvents: "none",
      }}
    >
      {/* <Box position="relative" width="100%" maxWidth="600px" margin="auto">
        <Image
          src={Title1BG}
          alt="Title background"
          width="100%"
          marginLeft="5%"
        />
        <VStack
          position="absolute"
          top="43%"
          left="50%"
          transform="translate(-50%, -50%)"
          spacing={4}
          align="center"
          width="100%"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="gold"
            textShadow="0 0 5px rgba(0,0,0,0.5)"
          >
            HOT GAMES
          </Text>
        </VStack>
      </Box> */}
      <Box
        backgroundImage={`url(${block3})`}
        backgroundRepeat="no-repeat"
        backgroundSize="100% 100%"
        width="100%"
        height={boxHeight}
        position="relative"
        marginBottom="60px"
      >
        <Text
          color="gold"
          fontSize={{ base: "20px", md: "26px", lg: "36px" }}
          fontWeight="bold"
          textAlign="center"
          position="absolute"
          top={{ base: "45px", md: "48px", lg: "85px" }}
          left="50%"
          transform="translateX(-45%)"
        >
          HOT GAMES
        </Text>

        <Flex
          direction="column"
          height="100%"
          justifyContent="flex-start"
          borderRadius="10px"
          padding="10px"
          marginTop={{ base: "120px", md: "120px", lg: "150px" }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 1, lg: 1 }}
            spacing={2}
            justifyItems="center"
          >
            {tag7_pic &&
              tag7_pic.map((game, index) => (
                <Link
                  key={index}
                  href={game.url || "#"}
                  isExternal={!!game.url}
                >
                  <LazyImage
                    src={`/UserFiles/${hash}/${game.pic}`}
                    alt="911win"
                    w={{ base: "100%", md: "100%", lg: "100%" }}
                    h="auto"
                    objectFit="cover"
                  />
                </Link>
              ))}
          </SimpleGrid>
          {/* {gameCategories.map((category, index) => (
            <Box key={index} marginTop="5px">
              <Text
                color="white"
                fontSize={{ base: "16px", md: "20px", lg: "20px" }}
                fontWeight="bold"
                textAlign="center"
                mb={2}
              >
                {category.title}
              </Text>
              <SimpleGrid
                columns={{ base: 3, md: 3, lg: 4 }}
                spacing={2}
                justifyItems="center"
              >
                {category.games.map((game) => (
                  <Link key={game.id} href={game.link} isExternal>
                    <LazyImage
                      src={game.image}
                      alt={`Game ${game.id}`}
                      w={{ base: "80px", md: "80px", lg: "120px" }}
                      h="auto"
                      objectFit="cover"
                    />
                  </Link>
                ))}
              </SimpleGrid>
            </Box>
          ))}
            */}
        </Flex>
      </Box>
    </Box>
  );
};

export default HotGames;
