const MemberInfoReducers = (state = {}, action) => {
  switch (action.type) {
    case "STORE_MEMBER_INFO":
      return (state = action.payload);
    case "CLEAR_MEMBER_INFO":
      return (state = {});

    default:
      return (state = {});
  }
};
export default MemberInfoReducers;
