import React from "react";
import { VStack, Link, Image, Box } from "@chakra-ui/react";
import fb from "../images/Common/fb.webp";
import telegram from "../images/Common/telegram.webp";
import zalo from "../images/Common/zalo.webp";

const SocialMediaButtons = ({ data }) => {
  if (!data || !data.data) return null;

  const { tag8_link0, tag8_link1, tag8_link2 } = data.data;

  const buttons = [
    {
      icon: telegram,
      link: tag8_link0,
      alt: "Telegram",
    },
    {
      icon: fb,
      link: tag8_link1,
      alt: "Facebook",
    },
    {
      icon: zalo,
      link: tag8_link2,
      alt: "Zalo",
    },
  ];

  return (
    <VStack
      position="fixed"
      bottom="100px"
      right="20px"
      spacing={1}
      zIndex={1000}
      willChange="transform" //告訴瀏覽器這個的變化不會影響到其他元素，這樣瀏覽器就可以對該元素進行硬件加速，從而提高性能。
    >
      {buttons.map((button, index) => (
        <Link href={button.link} isExternal key={index}>
          <Box
            as="button"
            borderRadius="full"
            overflow="hidden"
            width="48px"
            height="48px"
            _hover={{ transform: "scale(1.1)" }}
            transition="all 0.2s"
          >
            <Image
              src={button.icon}
              alt={button.alt}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Box>
        </Link>
      ))}
    </VStack>
  );
};

export default SocialMediaButtons;
