import React from "react";
import { Box, Image, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import Title1BG from "../images/Common/title1BG.webp";

const TitleOne = ({ data }) => {
  // 用 useBreakpointValue 根據螢幕寬度調整top
  const topValue = useBreakpointValue({ base: "55%", md: "50%", lg: "53%" });
  const spacingValue = useBreakpointValue({ base: 4, md: 4, lg: 8 });
  const fontSizeValue = useBreakpointValue({
    base: "24px",
    md: "28px",
    lg: "36px",
  });

  if (!data || !data.data) return null;
  const { tag3_title, tag4_memo } = data.data;

  return (
    <Box position="relative" width="100%" maxWidth="600px" margin="auto">
      <Image
        src={Title1BG}
        alt="Title background"
        width="100%"
        marginLeft="5%"
      />
      <VStack
        position="absolute"
        top={topValue} // 根據螢幕寬度調整top
        left="50%"
        transform="translate(-50%, -50%)"
        spacing={spacingValue}
        align="center"
        width="100%"
      >
        <Text
          fontSize={fontSizeValue}
          fontWeight="bold"
          color="gold"
          textShadow="0 0 5px rgba(0,0,0,0.5)"
        >
          {tag3_title}
        </Text>
        <Box
          dangerouslySetInnerHTML={{ __html: tag4_memo }}
          color="white"
          textAlign="center"
          fontSize="18px"
          maxWidth="80%"
        />
        {/* <Text fontSize="14px" color="white" textAlign="center" maxWidth="80%">
          Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho tro ngan tang Ho
          tro ngan tang
        </Text> */}
      </VStack>
    </Box>
  );
};

export default TitleOne;
