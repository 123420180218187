import React from "react";
import { Box, Image, Button, useTheme } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import carousel1 from "../images/Carousel/carousel1.webp";
import carousel2 from "../images/Carousel/carousel1.webp";
import carousel3 from "../images/Carousel/carousel1.webp";
import carousel4 from "../images/Carousel/carousel1.webp";
import carousel5 from "../images/Carousel/carousel1.webp";
import { stylesConfig } from "../helpers/stylesConfig";
import LazyImage from "./lazyImage";

const Carousel = ({ data }) => {
  if (!data || !data.data) return null;

  const { hash, tag1_pic0, tag1_pic1, tag1_pic2, tag1_pic3, tag1_pic4 } =
    data.data;
  const images = [tag1_pic0, tag1_pic1, tag1_pic2, tag1_pic3, tag1_pic4].filter(
    Boolean
  );

  return (
    <Box position="relative" height="100%" width="100%" overflow="hidden">
      <Splide
        options={{
          type: "fade",
          rewind: true,
          arrows: false,
          autoplay: true,
          interval: 5000,
          pauseOnHover: false, // 滑鼠移入停止
          pagination: false, // 分頁器不顯示
          width: "100%",
          height: "90vh",
          breakpoints: {
            768: {
              width: "100%",
              height: "40vh", // 螢幕寬度小於768px時高度是60vh
            },
            1024: {
              width: "100%",
              height: "90vh", // 螢幕寬度小於1024px時寬度是50vw，高度是100vh
            },
          },
        }}
      >
        {images?.map((image, index) => (
          <SplideSlide key={index}>
            <Box width="100vw" position="relative">
              <LazyImage
                src={`/UserFiles/${hash}/${image}`}
                alt={`Slide ${index + 1}`}
                objectFit="contain"
                objectPosition="center"
                width={{ base: "100%", md: "90%", lg: "80%", xl: "50%" }}
                //height="100%"
                position="absolute"
                top="0"
                left="0"
                sx={{
                  // 讓圖片下方有漸層遮罩
                  maskImage:
                    "linear-gradient(to bottom, rgba(0,0,0,1) calc(100% - 120px), rgba(0,0,0,0) 100%)",
                  WebkitMaskImage:
                    "linear-gradient(to bottom, rgba(0,0,0,1) calc(100% - 120px), rgba(0,0,0,0) 100%)",
                }}
              />
            </Box>
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default Carousel;
