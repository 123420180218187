import { combineReducers } from "redux";

import { createRouterReducer } from "@lagunovsky/redux-react-router";
import history from "../history";
import FormReducers from "./form";
import PublicListReducers from "./public_list";
import NormalListReducers from "./normal_list";
import GlobalLoadingReducers from "./global_loading";
import MemberInfoReducers from "./member_info";
import PublicResultReducers from "./public_result";

const AllReducers = combineReducers({
  FORM: FormReducers,
  PUBLIC_LOTTERY_LIST: PublicListReducers,
  NORMAL_LOTTERY_LIST: NormalListReducers,
  GLOBAL_LOADING: GlobalLoadingReducers,
  MEMBER_INFO: MemberInfoReducers,
  PUBLIC_RESULT: PublicResultReducers,
  navigator: createRouterReducer(history),
});

export default AllReducers;
