import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const brandPrimary = definePartsStyle({
  // define the part you're going to style
  field: {
    border: "1px solid",
    borderColor: "primary.600",
    color: "rgb(73,80,88)",
    fontWeight: "normal",
    background: "primary.700",
    borderRight: "common",
    py: "5px",
    px: "10px",
    fontSize: "15px",
    height: "35px",
    // Let's also provide dark mode alternatives
    _dark: {
      background: "dark.100",
      borderColor: "primary.600",
      color: "white",
    },
    _focus: {
      borderColor: "primary.500",
    },
    _hover: {
      borderColor: "primary.500",
    },
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
});
