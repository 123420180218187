import axios from "axios";
import Cookies from "js-cookie";

const API_URL = "/API/restfulAPI.php";

export const fetchData = async (code) => {
  try {
    const response = await axios.post(API_URL, {
      act: "getdata",
      code: code,
    });
    return response.data;
  } catch (error) {
    console.error("無法取得資料:", error);
    throw error;
  }
};
