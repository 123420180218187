import { parseSomething } from "../utils/parseSomething";
import block1Theme1 from "../images/Common/block1.webp";
import block1Theme2 from "../images/Common/block1.webp";

// 不同主題的BlockOne圖片
const themeImages = {
  theme1: {
    block1: block1Theme1,
  },
  theme2: {
    block1: block1Theme2,
  },
};

// 取特定主題的圖片
export const getThemeImage = (theme, imageName) => {
  return themeImages[theme]?.[imageName] || themeImages.theme1[imageName]; // 預設用 theme1
};

// 導出主題配置
export const themeConfig = {
  initialColorMode: "theme1",
  useSystemColorMode: false,
};

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        "911winleadpage-main": {
          leaderBoard: {
            left: "#c18f30",
            right: "#d0a044",
            border: "#a67b29",
            avatar: "#debc7b",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#ffffff",
            200: "#faf4e9",
            300: "#f3e6ce",
            400: "#ecd8b2",
            500: "#debc7b",
            600: "#d7ae5f",
            700: "#d0a044",
            800: "#cd9936",
            900: "#c18f30",
            950: "#b3852d",
            1000: "#a67b29",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(222, 188, 123, 0.4)",
            "text-mask-from": "#ecd8b2",
          },
          "second-brand": {
            500: "#181c35",
            700: "#1d2240",
          },
          "right-promotion-box": {
            to: "#e5ca97",
            from: "#ecd8b2",
            border: "#d7ae5f",
            hover_to: "#debc7b",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#e5ca97",
          },
          "v3-second-brand": {
            100: "#242a50",
            200: "#22274a",
            300: "#1f2445",
            400: "#1d2240",
            500: "#181c35",
            600: "#161930",
            700: "#13162a",
            800: "#121528",
            900: "#111425",
            950: "#101222",
            1000: "#0e1120",
            "500-opacity": "#0e1120",
            "700-opacity": "#0c0e1b",
          },
          "v3-profile": {
            "banner-from": "#ffffff",
            "banner-to": "#faf4e9",
            "banner-border": "#d0a044",
            "banner-title": "#f3e6ce",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#080e30",
            150: "#1c1407",
            200: "#05081b",
            250: "#d0a044",
            300: "#d7ae5f",
            400: "#e5ca97",
            500: "#debc7b",
            600: "#ecd8b2",
            700: "#faf4e9",
          },
        },
        "deep-ss": {
          dark: {
            100: "#371919",
            150: "#4a2121",
            200: "#5c2a2a",
            250: "#944242",
            300: "#a64b4b",
            400: "#bc6a6a",
            500: "#b45858",
            600: "#c57d7d",
            700: "#d5a2a2",
          },
          brand: {
            100: "#deb4b4",
            200: "#d5a2a2",
            300: "#cd8f8f",
            400: "#c57d7d",
            500: "#b45858",
            600: "#a64b4b",
            700: "#944242",
            800: "#8b3e3e",
            900: "#813a3a",
            950: "#783636",
            1000: "#6f3232",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(180, 88, 88, 0.4)",
            "text-mask-from": "#c57d7d",
          },
          "v3-profile": {
            "banner-to": "#c8daea",
            "banner-from": "#eff4f9",
            "banner-title": "#c8daea",
            "banner-border": "#3d709c",
          },
          leaderBoard: {
            left: "#813a3a",
            right: "#944242",
            avatar: "#b45858",
            border: "#6f3232",
            "v3-border": "#C9D0E9",
          },
          "second-brand": {
            500: "#538bbc",
            700: "#7aa5cb",
          },
          "v3-second-brand": {
            200: "#eff4f9",
            300: "#a1c0da",
            400: "#7aa5cb",
            500: "#538bbc",
            700: "#447eb0",
            900: "#356289",
            "500-opacity": "#2e5475",
            "700-opacity": "#264662",
          },
          "right-promotion-box": {
            to: "#bc6a6a",
            from: "#c57d7d",
            border: "#a64b4b",
            hover_to: "#b45858",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#bc6a6a",
          },
        },
        "deep-blue": {
          dark: {
            100: "#232A37",
            150: "#232937",
            200: "#171B27",
            250: "#2F313C",
            300: "#0F0D1A",
            400: "#110F1E",
            500: "#0B0916",
            600: "#090714",
            700: "#4F5C78",
          },
          brand: {
            100: "#95C8FE",
            300: "#567cf5",
            500: "#3a66f3",
            600: "#1e50f1",
            700: "#0e41e3",
            900: "#0a30aa",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(58, 102, 243, 0.4)",
            "text-mask-from": "#335EEE",
          },
          leaderBoard: {
            left: "#242D4C",
            right: "#33406B",
            avatar: "#151b2e",
            border: "#0f1428",
          },
          "second-brand": {
            500: "#7392f6",
            700: "#0c38c7",
          },
          "right-promotion-box": {
            to: "#567cf5",
            from: "#7392f6",
            border: "#1e50f1",
            hover_to: "#3a66f3",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#567cf5",
          },
        },
        "light-blue": {
          dark: {
            100: "#0e2a2d",
            150: "#123538",
            200: "#154044",
            250: "#39aab5",
            300: "#46b9c5",
            400: "#74cbd3",
            500: "#5dc2cc",
            600: "#8ad3da",
            700: "#b7e4e8",
          },
          brand: {
            100: "#ceedf0",
            200: "#b7e4e8",
            300: "#a1dce1",
            400: "#8ad3da",
            500: "#5dc2cc",
            600: "#46b9c5",
            700: "#39aab5",
            800: "#359fa9",
            900: "#32949e",
            950: "#2e8a93",
            1000: "#2b7f88",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(93, 194, 204, 0.4)",
            "text-mask-from": "#8ad3da",
          },
          "v3-profile": {
            "banner-to": "#ffffff",
            "banner-from": "#ffffff",
            "banner-title": "#ffffff",
            "banner-border": "#56ca42",
          },
          leaderBoard: {
            left: "#32949e",
            right: "#39aab5",
            avatar: "#5dc2cc",
            border: "#2b7f88",
            "v3-border": "#C9D0E9",
          },
          "second-brand": {
            500: "#85d977",
            700: "#b4e8ac",
          },
          "v3-second-brand": {
            200: "#ffffff",
            300: "#e3f6e0",
            400: "#b4e8ac",
            500: "#85d977",
            700: "#6dd25d",
            900: "#46b833",
            "500-opacity": "#3c9e2c",
            "700-opacity": "#328325",
          },
          "right-promotion-box": {
            to: "#74cbd3",
            from: "#8ad3da",
            border: "#46b9c5",
            hover_to: "#5dc2cc",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#74cbd3",
          },
        },
        "deep-purple": {
          dark: {
            100: "#4e406e",
            150: "#232937",
            200: "#372E4B",
            250: "#2F313C",
            300: "#0F0D1A",
            400: "#110F1E",
            500: "#0B0916",
            600: "#090714",
            700: "#4F5C78",
          },
          brand: {
            100: "#DDA8F9",
            300: "#d8a7f1",
            400: "#B585D0",
            500: "#A467C5",
            600: "#9B62BB",
            700: "#935CB1",
            800: "#6A4380",
            900: "#72478A",
            950: "#5A396C",
            1000: "#261F34",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(58, 102, 243, 0.4)",
            "text-mask-from": "#5A396C",
          },
          "v3-profile": {
            "banner-to": "#F0E5F3",
            "banner-from": "#F7F4FE",
            "banner-title": "#F0E5F3",
            "banner-border": "#C3A9D4",
          },
          leaderBoard: {
            left: "#242D4C",
            right: "#33406B",
            avatar: "#151b2e",
            border: "#0f1428",
            "v3-border": "#C9D0E9",
          },
          "second-brand": {
            500: "#7392f6",
            700: "#0c38c7",
          },
          "v3-second-brand": {
            200: "#70f0d7",
            300: "#51D8BC",
            400: "#48C4A3",
            500: "#49C5A3",
            700: "#3FAC8F",
            900: "#338F78",
            "500-opacity": "#2C8068",
            "700-opacity": "#26715C",
          },
          "right-promotion-box": {
            to: "#567cf5",
            from: "#7392f6",
            border: "#1e50f1",
            hover_to: "#3a66f3",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#567cf5",
          },
        },
        "deep-blue-v2": {
          dark: {
            100: "#051855",
            150: "#072071",
            200: "#09288e",
            250: "#0e41e3",
            300: "#1e50f1",
            400: "#567cf5",
            500: "#3a66f3",
            600: "#7392f6",
            700: "#abbefa",
          },
          brand: {
            100: "#c8d4fc",
            200: "#abbefa",
            300: "#8fa8f8",
            400: "#7392f6",
            500: "#3a66f3",
            600: "#1e50f1",
            700: "#0e41e3",
            800: "#0d3cd5",
            900: "#0c38c7",
            950: "#0b34b8",
            1000: "#0a30aa",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-to": "#F6EB20",
            "jackpot-from": "#FEF87D",
          },
          "game-item": {
            mask: "rgba(58, 102, 243, 0.4)",
            "text-mask-from": "#7392f6",
          },
          "v3-profile": {
            "banner-to": "#ffffff",
            "banner-from": "#ffffff",
            "banner-title": "#ffffff",
            "banner-border": "#2f5df2",
          },
          leaderBoard: {
            left: "#0c38c7",
            right: "#0e41e3",
            avatar: "#3a66f3",
            border: "#0a30aa",
            "v3-border": "#C9D0E9",
          },
          "second-brand": {
            500: "#7392f6",
            700: "#b7c7fa",
          },
          "v3-second-brand": {
            200: "#ffffff",
            300: "#fbfcff",
            400: "#b7c7fa",
            500: "#7392f6",
            700: "#5178f4",
            900: "#0f44ed",
            "500-opacity": "#0d3acc",
            "700-opacity": "#0b30aa",
          },
          "right-promotion-box": {
            to: "#567cf5",
            from: "#7392f6",
            border: "#1e50f1",
            hover_to: "#3a66f3",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#567cf5",
          },
        },
        gold: {
          leaderBoard: {
            left: "#a9922e",
            right: "#c2a734",
            border: "#917d27",
            avatar: "#d4be5f",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#f5efd8",
            200: "#eee5c0",
            300: "#e8dba8",
            400: "#e1d28f",
            500: "#d4be5f",
            600: "#cdb447",
            700: "#c2a734",
            800: "#b59d31",
            900: "#a9922e",
            950: "#9d882a",
            1000: "#917d27",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(212, 190, 95, 0.4)",
            "text-mask-from": "#e1d28f",
          },
          "second-brand": {
            500: "#a4a4a4",
            700: "#c5c5c5",
          },
          "right-promotion-box": {
            to: "#dbc877",
            from: "#e1d28f",
            border: "#cdb447",
            hover_to: "#d4be5f",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#dbc877",
          },
          "v3-second-brand": {
            100: "#f6f6f6",
            200: "#e6e6e6",
            300: "#d5d5d5",
            400: "#c5c5c5",
            500: "#a4a4a4",
            600: "#949494",
            700: "#838383",
            800: "#7b7b7b",
            900: "#737373",
            950: "#6b6b6b",
            1000: "#626262",
            "500-opacity": "#626262",
            "700-opacity": "#525252",
          },
          "v3-profile": {
            "banner-from": "#fbf9f0",
            "banner-to": "#eee5c0",
            "banner-border": "#c2a734",
            "banner-title": "#e8dba8",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#2A2A2A",
            150: "#181507",
            200: "#000000",
            250: "#c2a734",
            300: "#cdb447",
            400: "#dbc877",
            500: "#d4be5f",
            600: "#e1d28f",
            700: "#eee5c0",
          },
        },
      }
    : parseSomething(window.web_style_config);
