import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Link } from "@chakra-ui/layout";
import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import buttonOrange from "../images/Common/buttonBG.webp";
import LazyImage from "./lazyImage";

const MiddleButton = ({ data }) => {
  if (!data || !data.data) return null;

  const { tag2_button_text, tag2_button_link, hash } = data.data;

  return (
    <Box
      position="relative"
      marginTop="-100px"
      top="80%"
      left="50%"
      transform="translate(-50%, 20%)"
      textAlign="center"
      zIndex="1"
    >
      <Image
        src={stylesConfig.mainLogoOrigin}
        alt="911 Logo"
        mb={4}
        maxWidth="200px"
        mx="auto"
      />
      <Box
        as={Link}
        href={tag2_button_link}
        display="inline-block"
        position="relative"
        cursor="pointer"
        isExternal
      >
        <Image src={buttonOrange} alt={tag2_button_text} maxWidth="250px" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          color="white"
          fontSize="lg"
          fontWeight="bold"
        >
          {tag2_button_text}
        </Box>
      </Box>
    </Box>
  );
};

export default MiddleButton;
