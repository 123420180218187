import React from "react";
import { Box, Image, useColorMode } from "@chakra-ui/react";
import block1 from "../images/Common/block1.webp";
import videoSource from "../images/Common/sampleVideo.mp4";
import imageSource from "../images/Common/samplePic.webp";
import LazyImage from "./lazyImage";
import { getThemeImage } from "../chakraTheme/colorScheme";
import block1BG from "../images/Common/block1BG.webp";

const BlockOne = ({ data }) => {
  const { colorMode } = useColorMode();

  if (!data || !data.data) return null;

  const { hash, tag5_pic0 } = data.data;
  const imagePath = `/UserFiles/${hash}/${tag5_pic0}`;

  return (
    <Box
      position="relative"
      width="100%"
      maxWidth="600px"
      margin="auto"
      overflow="hidden"
    >
      <LazyImage src={block1BG} alt="911" width="100%" height="auto" />
      <Box
        as="div"
        position="absolute"
        paddingTop="1%"
        top="25px"
        left="25px"
        right="25px"
        bottom="25px"
      >
        <LazyImage
          src={imagePath}
          alt="Content"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          objectFit="cover"
        />

        {/* <LazyImage
            src={backgroundImage}
            alt="Default background"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            objectFit="cover"
          /> */}
      </Box>
    </Box>
  );
};

export default BlockOne;
