import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Image, useColorModeValue } from "@chakra-ui/react";
import { COMMON_ALT } from "../constant";
import { stylesConfig } from "../helpers/stylesConfig";

const LazyImage = ({
  src,
  alt = COMMON_ALT,
  className = "",
  fetchPriority,
  onError,
  sx,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const bgColor = useColorModeValue("light.200", "dark.100");

  return (
    <Box position="relative" {...rest}>
      <LazyLoadImage
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        onError={onError}
        fetchpriority={fetchPriority}
        className={className}
        style={{
          visibility: loaded ? "visible" : "hidden",
          ...sx,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
      {!loaded && (
        <Box
          position="absolute"
          top="0"
          left="0"
          bg={bgColor}
          zIndex={1}
          w="100%"
          h="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderTopRadius="common"
        >
          <Image
            src={stylesConfig.mainLogoOrigin}
            cursor="pointer"
            w="120px"
            objectFit="cover"
            pointerEvents="all"
            alt="911win"
            filter="grayscale(100%)"
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(LazyImage);
