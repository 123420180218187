import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Link,
  VStack,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  FaFacebookF,
  FaYoutube,
  FaTiktok,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { SiThreads } from "react-icons/si";

import footerCoin from "../images/Common/footer.webp";
import { stylesConfig } from "../helpers/stylesConfig";

const Footer = () => {
  const iconSize = useBreakpointValue({
    base: "16px",
    md: "24px",
    lg: "32px",
  });

  return (
    <Box
      as="footer"
      backgroundImage="linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.5))"
      color="white"
      py={{ base: 20, md: 24, lg: 40 }}
      position="relative"
    >
      <Flex
        direction="column"
        align="center"
        maxWidth="800px"
        margin="0 auto"
        px={4}
      >
        <Image
          src={stylesConfig.mainLogoOrigin}
          alt="911 Logo"
          width="150px"
          mb={4}
          mx="auto"
        />

        <Text textAlign="center" fontSize="sm" p={4}>
          Hệ thống này được vận hành bởi một công ty giải trí hợp pháp được cấp
          phép bởi Hiệp hội Giải trí Quốc tế. Vui lòng đảm bảo bạn đã đủ 18 tuổi
          trước khi đăng ký.
        </Text>

        <Text fontWeight="bold" mb={2}>
          Theo dõi chúng tôi
        </Text>

        {/* <HStack spacing={4} mb={4}>
          <Link href="#">
            <FaFacebookF fontSize={iconSize} />
          </Link>
          <Link href="#">
            <FaYoutube fontSize={iconSize} />
          </Link>
          <Link href="#">
            <FaTiktok fontSize={iconSize} />
          </Link>
          <Link href="#">
            <FaInstagram fontSize={iconSize} />
          </Link>
          <Link href="#">
            <FaTwitter fontSize={iconSize} />
          </Link>
          <Link href="#">
            <SiThreads fontSize={iconSize} />
          </Link>
        </HStack> */}
      </Flex>

      <Image
        src={footerCoin}
        alt=""
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        width="100%"
        objectFit="cover"
      />
    </Box>
  );
};

export default Footer;
